<template>
  <div class="mt-1 flex rounded shadow-sm">
    <div class="relative flex-grow focus-within:z-10">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
        <i class="fal fa-stamp"></i>
      </div>
      <input v-model="vatNumber" type="text" class="block w-full rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5 font-normal text-blue-600 border-gray-300" placeholder="VAT Number" >
    </div>
    <button @click="search" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
      <i class="fal fa-search"></i>
      <span class="ml-2">Search</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import {emptyCustomer} from "@/common/customer";

export default {
  name: "vat-field",
  data() {
    return {
      vatNumber: ''
    }
  },
  methods: {
    search: function() {
      axios.get("/manager/seller/data/check-vat/", { params: {countryCode: 'BE', vatNumber: this.vatNumber }}).then(response => {
        var data = response.data;
        var parts = data.address.split('\n');
        var partsCity = parts[parts.length-1].split(' ');
        var addressS = "";
        for(let i=0;i<parts.length-1;i++) {
          addressS += parts[i];
          if(i<(parts.length-2)) addressS += ", ";
        }
        var postcodeS = partsCity[0];
        var cityS = "";
        for(let i=1;i<partsCity.length;i++) {
          cityS += " " + partsCity[i];
        }
        var result = [];
        var item = emptyCustomer();
        item.companyCity = cityS;
        item.companyAddress = addressS;
        item.companyPostCode = postcodeS;
        item.companyName = data.name;
        item.companyVat = data.vatNumber;
        item.vatTaxable = "tri-state";
        item.vatCorporation = true;
        item.vatCorporation100 = true;
        result.push(item);
        if(item.companyName==='---') result = [];
        this.vatNumber = '';
        this.$emit('found', result);
      });
    }
  },
}
</script>

<style scoped>

</style>