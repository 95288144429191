import {generateUUID} from "./index";

export function emptyCustomer() {
    return {
        id: generateUUID(),
        manual: false,
        title: null,
        culture: 'FR',
        firstName: null,
        lastName: null,
        country: null,
        address: null,
        number: null,
        box: null,
        postCode: null,
        city: null,
        mobile: null,
        telephone: null,
        email: null,
        companyName: null,
        legalForm: null,
        companyVat: null,
        companyAddress: null,
        companyPostCode: null,
        companyCity: null,
        companyCountry: null,
        companyTelephone: null,
        companyEmail: null,
        vatTaxable: 'tri-state',
        vatCorporation: 'tri-state',
        vatCorporation100: 'tri-state',
        vatTaxableValue: null,
        disabledPerson: false,
        vatDiplomatic: false,
        vatBelgian: true,
        synced: false
    }
}