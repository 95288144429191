<template>
<fragment>
  <div class="flex justify-start">
    <label :class="['block text-sm font-medium leading-5', required && validating && noneStr(value) ? 'text-red-600': 'text-gray-700']" v-html="label">{{ label }}</label>
    <span class="text-sm ml-2 leading-5 text-gray-300 font-light hover:text-green-500 cursor-pointer" id="email-optional">
      <i v-if="info" v-tooltip.top-center="info"  class="fas fa-info-circle"></i>
    </span>
  </div>
  <div class="mt-1 relative rounded-sm shadow-sm w-full">
    <input :value="value" type="text" :placeholder="placeholder" v-on:input="updateValue($event.target.value)" :class="['block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded', required ? 'border-l-4' : '', required && validating && noneStr(value) ? 'border-red-600': 'border-gray-300']">
<!--    <div v-if="required" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-xs">-->
<!--      <i class="far fa-asterisk"></i>-->
<!--    </div>-->
  </div>
</fragment>
</template>

<script>
import {noneStr} from "@/common";

export default {
  name: "form-text-field",
  props: {
    id: {
      type: String
    },
    placeholder: {
      type: String,
      required: false
    },
    info: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: String
    },
    validating: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    noneStr(v) {
      return noneStr(v);
    },
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>

