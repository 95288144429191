<template>
  <form autocomplete="off" class="w-full flex flex-row">
    <div class="w-1/2 py-2 pl-2 pr-1">
      <div class=" border-2 rounded-lg border-gray-200 bg-white p-3 h-full">
        <div class="flex flex-row">
          <div class="px-4 pt-2 text-3xl text-blue-700"><i class="fal fa-user"></i></div>
          <div class="pl-2">
            <div class="font-medium text-xl">Person</div>
            <div class="font-light">Enter customer information manually</div>
          </div>
        </div>
        <div class="">
          <div class="flex flex-col items-center mt-6 px-4 w-full">
            <div class="w-full flex flex-row">
              <div class="w-1/2">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Title</label>
                </div>
                <div class="mt-1 relative w-full">
                  <span :class="['relative z-0 inline-flex shadow-sm rounded', validating && noneStr(customer().title) ? 'bg-red-100' : '']">
                    <div @click="selectTitle('Mr')" :class="['cursor-pointer', nil(customer().title).toLowerCase() === 'mr' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', 'relative inline-flex items-center px-2 h-9 rounded-l-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      Mr
                    </div>
                    <div @click="selectTitle('Mrs')" :class="['cursor-pointer', nil(customer().title).toLowerCase() === 'mrs' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', '-ml-px relative inline-flex items-center px-2 h-9 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      Mrs
                    </div>
                    <div @click="selectTitle('Miss')" :class="['cursor-pointer', nil(customer().title).toLowerCase() === 'miss' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', '-ml-px relative inline-flex items-center px-2 h-9 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      Miss
                    </div>
                    <div @click="selectTitle('Mx')" :class="['cursor-pointer', nil(customer().title).toLowerCase() === 'mx' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', '-ml-px relative inline-flex items-center px-2 h-9 rounded-r-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      Mx
                    </div>
                  </span>
                </div>
              </div>
              <div class="w-1/2 pl-6">
                <div class="flex flex-row justify-end pr-1">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Language</label>
                </div>
                <div class="mt-1 relative w-full flex flex-row justify-end">
                  <span :class="['relative z-0 inline-flex shadow-sm rounded', validating && noneStr(customer().culture) ? 'bg-red-100' : '']">
                    <div @click="selectCulture('FR')" :class="['cursor-pointer', nil(customer().culture).toUpperCase() == 'FR' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', 'relative inline-flex items-center px-2 h-9 rounded-l-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      FR
                    </div>
                    <div @click="selectCulture('NL')" :class="['cursor-pointer', nil(customer().culture).toUpperCase() == 'NL' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', '-ml-px relative inline-flex items-center px-2 h-9 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      NL
                    </div>
                    <div @click="selectCulture('DE')" :class="['cursor-pointer', nil(customer().culture).toUpperCase() == 'DE' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', '-ml-px relative inline-flex items-center px-2 h-9 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      DE
                    </div>
                    <div @click="selectCulture('EN')" :class="['cursor-pointer', nil(customer().culture).toUpperCase() == 'EN' ? 'hover:text-gray-300 bg-blue-600 text-white' : 'bg-transparent text-gray-700 hover:text-gray-500', '-ml-px relative inline-flex items-center px-2 h-9 rounded-r-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150']">
                      EN
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="w-full mt-2 flex flex-row">
              <div class="w-2/5">
                <form-text-field v-model="customer().firstName" label="First name" :validating="validating" :required="false" placeholder="" />
              </div>
              <div class="w-3/5 pl-2">
                <form-text-field v-model="customer().lastName" label="Last name" :validating="validating" :required="true" placeholder="" />
              </div>
            </div>
            <div class="w-full mt-2">
              <div class="flex justify-start">
                <label class="block text-sm font-medium leading-5 text-gray-700">Address</label>
              </div>
              <div class="mt-1 mb-4 flex rounded shadow-sm">
                <div class="relative flex-grow focus-within:z-10">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                    <i class="fas fa-search"></i>
                  </div>
                  <input v-model="searchAddress" id="person-address-autocomplete" type="text" class="block w-full rounded pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-4 text-gray-600 border-gray-300 rounded" placeholder="Search address">
                </div>
              </div>
              <div class="mt-1 relative rounded-sm shadow-sm w-full">
                <select v-model="customer().country" class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 font-bold text-blue-600 rounded">
                  <option selected value="BE">Belgium</option>
                  <option value="FR">France</option>
                  <option value="NL">Netherlands</option>
                </select>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="rounded-sm shadow-sm w-4/6">
                  <input v-model="customer().address"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Address">
                </div>
                <div class="rounded-sm shadow-sm w-1/6 ml-2 ">
                  <input v-model="customer().number"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Nr" >
                </div>
                <div class="rounded-sm shadow-sm w-1/6 ml-2">
                  <input v-model="customer().box"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Box" >
                </div>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="rounded-sm shadow-sm w-1 w-1/4">
                  <input v-model="customer().postCode"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Postcode" >
                  <!--                                    <autocomplete title="Postcode" v-on:selected="selectPostcode" v-on:change="changePostcode" />-->
                </div>
                <div class="ml-2 rounded-sm shadow-sm w-3/4">
                  <input v-model="customer().city"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="City" >
                </div>
              </div>
            </div>
            <div class="w-full mt-2">
              <div class="flex justify-start">
                <label class="block text-sm font-medium leading-5 text-gray-700">Contact</label>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="w-1/2 pr-2">
                  <div class="relative rounded-sm shadow-sm w-full">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                      <i class="fal fa-phone"></i>
                    </div>
                    <input v-model="customer().telephone" type="text" class="pl-10 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Telephone" >
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="relative rounded-sm shadow-sm w-full">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                      <i class="fal fa-mobile-android"></i>
                    </div>
                    <input v-model="customer().mobile" type="text" class="pl-8 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Mobile" >
                  </div>
                </div>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="relative rounded-sm shadow-sm w-full">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                    <i class="fal fa-at"></i>
                  </div>
                  <input v-model="customer().email" type="text" class="pl-10 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Email" >
                </div>
              </div>
            </div>
            <div class="mt-3 w-48 flex flex-col items-center">
              <a v-on:click="reset" class="mt-2 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer">
                Reset
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/2 pl-1 py-2 pr-2">
      <div class=" border-2 rounded-lg border-gray-200 bg-white p-3 h-full">
        <div class="flex flex-row">
          <div class="px-4 pt-2 text-3xl text-blue-700"><i class="fal fa-building"></i></div>
          <div class="pl-2">
            <div class="font-medium text-xl">Company</div>
            <div class="font-light">Enter Company information manually</div>
          </div>
        </div>
        <div class="">
          <div class="flex flex-col items-center mt-6 px-4 w-full">
            <div class="w-full flex flex-col">
              <div class="flex justify-start">
                <label class="block text-sm font-medium leading-5 text-gray-700">Vat Number</label>
              </div>
              <vat-field v-on:found="foundVat"></vat-field>
            </div>
            <div class="w-full mt-2 flex flex-col">
              <div class="flex justify-start">
                <label  class="block text-sm font-medium leading-5 text-gray-700">Company name</label>
              </div>
              <div class="mt-1 relative rounded-sm shadow-sm w-full">
                <input v-model="customer().companyName" type="text" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="" >
              </div>
            </div>
            <div class="w-full mt-2">
              <div class="flex justify-start">
                <label class="block text-sm font-medium leading-5 text-gray-700">Address</label>
              </div>
              <div class="mt-1 mb-4 flex rounded shadow-sm">
                <div class="relative flex-grow focus-within:z-10">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                    <i class="fas fa-search"></i>
                  </div>
                  <input v-model="searchCompanyAddress" id="company-address-autocomplete" type="text" class="block w-full rounded pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-4 text-gray-600 border-gray-300 rounded" placeholder="Search address">
                </div>
              </div>
              <div class="mt-1 relative rounded-sm shadow-sm w-full">
                <select  v-model="customer().companyCountry" class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 font-bold text-blue-600 rounded">
                  <option selected value="BE">Belgium</option>
                  <option value="FR">France</option>
                  <option value="NL">Netherlands</option>
                </select>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="rounded-sm shadow-sm w-4/6">
                  <input v-model="customer().companyAddress"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Address" >
                </div>
                <div class="rounded-sm shadow-sm w-1/6 ml-2 ">
                  <input v-model="customer().companyNumber"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Nr" >
                </div>
                <div class="rounded-sm shadow-sm w-1/6 ml-2">
                  <input v-model="customer().companyBox"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Box" >
                </div>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="rounded-sm shadow-sm w-1 w-1/4">
                  <input v-model="customer().companyPostCode"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Postcode" >
                </div>
                <div class="ml-2 rounded-sm shadow-sm w-3/4">
                  <input v-model="customer().companyCity"  type="text" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="City" >
                </div>
              </div>
            </div>
            <div class="w-full mt-2">
              <div class="flex justify-start">
                <label class="block text-sm font-medium leading-5 text-gray-700">Contact</label>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="w-1/2 pr-2">
                  <div class="relative rounded-sm shadow-sm w-full">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                      <i class="fal fa-phone"></i>
                    </div>
                    <input v-model="customer().companyTelephone" type="text" class="pl-10 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Telephone" >
                  </div>
                </div>
                <div class="w-1/2">
                </div>
              </div>
              <div class="mt-2 flex flex-row">
                <div class="relative rounded-sm shadow-sm w-full">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                    <i class="fal fa-at"></i>
                  </div>
                  <input v-model="customer().companyEmail" type="text" class="pl-10 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Email" >
                </div>
              </div>
            </div>
            <div class="mt-3 w-48 flex flex-col items-center">
              <a v-on:click="reset" class="mt-2 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer">
                Reset
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { emptyCustomer } from '@/common/customer';
import vatField from '@/components/vat-field';
import formTextField from '@/components/form-text-field';

export default {
  name: "Customer",
  components: {
    'vat-field': vatField,
    'form-text-field': formTextField
  },
  data() {
    return {
      autocomplete: null,
      companyAutocomplete: null,
      searchAddress: '',
      searchCompanyAddress: '',
      validating: false
    }
  },
  methods: {
    customer() {
      return this.$store.getters['seller/lead'].customer;
    },
    reset() {
      return this.$store.commit('seller/setCustomer', emptyCustomer());
    },
    selectTitle(title) {
      this.$store.getters['seller/lead'].customer.title = title;
    },
    selectCulture(culture) {
      this.$store.getters['seller/lead'].customer.culture = culture;
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace();
      _.each(place.address_components, a => {
        if(a.types.includes('street_number')) this.$store.getters['seller/lead'].customer.number = a.long_name;
        if(a.types.includes('route')) this.$store.getters['seller/lead'].customer.address = a.long_name;
        if(a.types.includes('locality')) this.$store.getters['seller/lead'].customer.city = a.long_name;
        if(a.types.includes('country')) this.$store.getters['seller/lead'].customer.country = a.short_name;
        if(a.types.includes('postal_code')) this.$store.getters['seller/lead'].customer.postCode = a.long_name;
      });
      this.searchAddress = '';
    },
    fillInCompanyAddress() {
      const place = this.autocomplete.getPlace();
      _.each(place.address_components, a => {
        if(a.types.includes('street_number')) this.$store.getters['seller/lead'].customer.companyNumber = a.long_name;
        if(a.types.includes('route')) this.$store.getters['seller/lead'].customer.companyAddress = a.long_name;
        if(a.types.includes('locality')) this.$store.getters['seller/lead'].customer.companyCity = a.long_name;
        if(a.types.includes('country')) this.$store.getters['seller/lead'].customer.companyCountry = a.short_name;
        if(a.types.includes('postal_code')) this.$store.getters['seller/lead'].customer.companyPostCode = a.long_name;
      });
      this.searchCompanyAddress = '';
    },
    foundVat(items) {
      if(items.length===0) return;
      const item = items[0];
      this.$store.getters['seller/lead'].customer.companyCity = item.companyCity;
      this.$store.getters['seller/lead'].customer.companyAddress = item.companyAddress;
      this.$store.getters['seller/lead'].customer.companyPostCode = item.companyPostCode;
      this.$store.getters['seller/lead'].customer.companyName = item.companyName;
      this.$store.getters['seller/lead'].customer.companyVat = item.companyVat;
      this.$store.getters['seller/lead'].customer.vatTaxable = item.vatTaxable;
      this.$store.getters['seller/lead'].customer.vatCorporation = item.vatCorporation;
      this.$store.getters['seller/lead'].customer.vatCorporation100 = item.vatCorporation100;
    }
  },
  mounted() {
    if(this.$store.getters['seller/lead'].customer===undefined||this.$store.getters['seller/lead'].customer===null)
      this.$store.getters['seller/lead'].customer = emptyCustomer();
    this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("person-address-autocomplete"), { types: ["geocode"] });
    this.companyAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById("company-address-autocomplete"), { types: ["geocode"] });
    this.autocomplete.addListener("place_changed", this.fillInAddress);
    this.companyAutocomplete.addListener("place_changed", this.fillInCompanyAddress);
    setTimeout(function() {
      window.$("input").attr("autocomplete", "none");
    }, 400);
  },
}
</script>

<style scoped>

</style>